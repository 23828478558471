<template>
    <b-modal v-model="modals.newCart" :title="$t('general.newOrder')" content-class="card" centered
        header-class="card-header" @hidden="resetNewCart()" cancel-variant="danger" size="lg" hide-header-close>

        <v-observer ref="formCartCurrency" class="form-rounded-inputs" @submit.prevent="fnValidateNewCart()">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('tables.currency') ">
                <div class="form-group">
                    <label for="" v-text=" $t('tables.currency') "> </label>
                    <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                        <select class="custom-select text-capitalize" v-model="newCart.currency"
                            :class=" { 'is-invalid ':errors.length > 0 } ">
                            <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                            <option :value="currency" v-for=" (currency,index) in CONST.CURRENCIES " :key="index"
                                v-text=" currency ">
                            </option>
                        </select>
                        <div class="input-group-append">
                            <label class="input-group-text "> <i class="fa fa-funnel-dollar"></i> </label>
                        </div>
                    </div>
                    <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                    </div>
                </div>
            </v-validation>
        </v-observer>
        <div class="bg-light p-4">
            <p class="text-center text-muted f-w-500" v-text="$t('messages.newOrderCurrency')"></p>
        </div>

        <template v-slot:modal-footer>
            <div class="d-flex w-100">
                <button @click=" resetNewCart()" class="btn ml-auto" v-text=" $t('general.button.close') "></button>
                <button @click=" fnValidateNewCart()" class="btn btn-success" :disabled="loading.newCart">
                    <i class="fa fa-spinner fa-spin " v-if="loading.newCart"></i>
                    <span v-else v-text=" $t('general.button.create') "></span>
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex'
    export default {
        name: "OrdersCreateNewCart",
        watch: {},
        computed: {
            ...mapState('EcartOrders', ['modals', 'newCart', 'loading'])
        },
        data() {
            return {
                activeTab: "details",
            }
        },
        methods: {
            ...mapActions('EcartOrders', ['fnApiNewCart']),
            ...mapMutations('EcartOrders', ['resetNewCart']),
            async fnValidateNewCart() {
                if (await this.$refs.formCartCurrency.validate()) {
                    this.fnApiNewCart()
                }
            },
        },
        mounted() {}
    }
</script>